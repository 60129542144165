import { ArrowForwardRounded, Code, LockPerson, Mail, MailLock } from "@mui/icons-material";
import { Card, CardContent, Stack, Typography } from "@mui/material";
import { has } from "lodash";
import { type NextPage } from "next";
import { Trans } from "next-i18next";
import NextImage from "next/image";
import { useRouter } from "next/router";
import { type GetServerSideProps, type Route } from "nextjs-routes";
import { useBoolean } from "react-use";
import roundedSpacySvg from "~/assets/illustrations/rounded-spacy.svg";
import { value } from "~/components/helpers";
import { Layout } from "~/components/layout/layout";
import { Icon } from "~/components/ui/core/Icon";
import { Modal } from "~/components/ui/core/Modal";
import { Button } from "~/components/ui/core/button";
import { LoginLinkModal } from "~/components/ui/login-link-modal";
import { SamlLoginModal } from "~/components/ui/saml-login-modal";
import { config } from "~/config";
import { useIntercomForFigures } from "~/hooks/useIntercomForFigures";
import { useSession } from "~/hooks/useSession";
import { useI18n } from "~/lib/i18n/use-i18n";
import { redirect } from "~/lib/redirect";
import { ssr } from "~/lib/ssr";
import { useDevSignInMutation } from "~/pages/api/auth/dev-sign-in";

type Props = {
  devEmail?: string;
};

const handler: GetServerSideProps<Props> = async (ctx) => {
  if (!!ctx.req.user) {
    return redirect({ pathname: "/home" });
  }

  return {
    props: {
      ...(config.app.isLocal && {
        devEmail: config.dev.emailOverride,
      }),
    },
  };
};

export const getServerSideProps = ssr(handler, {
  authentication: { optional: true },
});

const Page: NextPage<Props> = ({ devEmail }) => {
  const { t } = useI18n();
  const { signIn } = useSession();
  const router = useRouter();
  const intercom = useIntercomForFigures();

  const redirect = value(() => {
    if (!router.query.redirect) {
      return "/home";
    }

    const redirect = decodeURIComponent(router.query.redirect as string);
    if (redirect.startsWith("/")) {
      return redirect;
    }

    return "/home";
  });
  const accountBlocked = router.query.error === "account_blocked";
  const noAccount = router.query.error === "auth_failed";
  const samlError = router.query.error === "saml_error";
  const authenticationRequiresProviderConfirmation =
    router.query["authentication-requires-provider-confirmation"] === "true";
  const email = router.query.email as string;

  const forceLoginLink = has(router.query, "login-link");
  const forceSsoSaml = has(router.query, "sso-saml");
  const [showEmailModal, setShowEmailModal] = useBoolean(forceLoginLink);
  const [showSamlModal, setShowSamlModal] = useBoolean(forceSsoSaml);

  const devSignIn = useDevSignInMutation({
    onSuccess: () => {
      void router.push({ pathname: redirect ?? "/home" } as Route);
    },
  });

  return (
    <Layout>
      {authenticationRequiresProviderConfirmation && (
        <Modal
          isOpen
          title="Confirm your email address to sign-in to Figures"
          onClose={() => {
            void router.push({ pathname: "/sign-in" });
          }}
        >
          <Stack spacing={4} direction="row" className="mt-2" alignItems="center">
            <MailLock fontSize="large" className="text-gray-500" />
            <Stack>
              <Typography>
                <Trans i18nKey="pages.sign-in.email-verification" values={{ email }}>
                  <b />
                </Trans>
              </Typography>
            </Stack>
          </Stack>
        </Modal>
      )}

      <div className="mx-auto mt-16 flex max-w-6xl flex-col md:mt-24">
        {accountBlocked && (
          <Card className="border-l-4 border-secondary-400 bg-red-100">{t("pages.sign-in.account-blocked")}</Card>
        )}

        {noAccount && (
          <Card component={Stack} spacing={2} className="border-l-4 border-secondary-400 bg-red-100">
            <span className="font-semibold">{t("pages.sign-in.email-not-found-error", { email })}</span>
            <span>
              <Trans i18nKey="pages.sign-in.email-not-found-contact">
                <span
                  className="underline focus:outline-none"
                  onClick={() => {
                    intercom.showNewMessages();
                  }}
                />
              </Trans>
            </span>
          </Card>
        )}

        {samlError && (
          <Card className="border-l-4 border-secondary-400 bg-red-100">{t("pages.sign-in.saml-error")}</Card>
        )}

        <Card className="mt-0 md:mt-6">
          <CardContent className="flex flex-col p-8">
            <div className="mx-auto mb-8 flex flex-col content-center md:hidden">
              <NextImage alt="rounded spacy" src={roundedSpacySvg} height={80} width={80} />
            </div>
            {/* eslint-disable-next-line figures/forbid-props */}
            <Typography variant="h1" className="mr-auto bg-secondary-400 px-2 font-semibold">
              {t("pages.sign-in.title")}
            </Typography>
            {/* eslint-disable-next-line figures/forbid-props */}
            <Typography variant="subtitle2" className="mt-2 text-gray-500">
              {t("pages.sign-in.subtitle")}
            </Typography>

            <div className="relative mt-5 flex flex-col md:flex-row">
              <Stack spacing={2}>
                {devEmail && (
                  <Button
                    startIcon={<Code />}
                    variant="contained"
                    isLoading={devSignIn.isLoading}
                    onClick={async () => {
                      await devSignIn.mutateAsync({});
                    }}
                  >
                    {devEmail}
                  </Button>
                )}

                <Button
                  startIcon={<Icon name="google" />}
                  variant="outlined"
                  onClick={() => signIn("google", { redirect })}
                >
                  {t("pages.sign-in.google")}
                </Button>

                <Button startIcon={<Mail />} variant="outlined" onClick={() => setShowEmailModal(true)}>
                  {t("pages.sign-in.login-link")}
                </Button>
                <LoginLinkModal isOpen={showEmailModal} onClose={() => setShowEmailModal(false)} />

                <Button
                  startIcon={<Icon name="microsoft" />}
                  variant="outlined"
                  onClick={() => signIn("microsoft", { redirect })}
                >
                  {t("pages.sign-in.microsoft")}
                </Button>

                <Button startIcon={<LockPerson />} variant="outlined" onClick={setShowSamlModal}>
                  {t("pages.sign-in.saml-sso")}
                </Button>
                <SamlLoginModal isOpen={showSamlModal} onClose={() => setShowSamlModal(false)} />
              </Stack>

              <div className="ml-16 mr-7 hidden flex-col content-center md:flex">
                <NextImage alt="rounded spacy" src={roundedSpacySvg} height={120} width={120} />
              </div>
            </div>

            <div className="ml-auto mt-4 flex">
              {/* eslint-disable-next-line figures/forbid-props */}
              <Typography className="my-auto text-gray-500">{t("pages.sign-in.support-text")}</Typography>

              <Button
                className="ml-2"
                variant="text"
                color="secondary"
                onClick={() => intercom.showNewMessages()}
                endIcon={<ArrowForwardRounded />}
              >
                {t("pages.sign-in.support-button")}
              </Button>
            </div>
          </CardContent>
        </Card>
      </div>
    </Layout>
  );
};

export default Page;
