import HttpStatus from "http-status-codes";
import { type NextApiHandler } from "next";
import { object } from "yup";
import { api } from "~/lib/api";
import { createMutation } from "~/lib/react-query";
import { devSignIn } from "~/lib/session";

export const DevSignInSchema = object({}).noUnknown();

const handler: NextApiHandler = async (req, res) => {
  await devSignIn(req);

  res.status(HttpStatus.OK).json({});
};

export default api(handler, {
  method: "POST",
  authentication: { optional: true },
});

export const useDevSignInMutation = createMutation<typeof handler, typeof DevSignInSchema>({
  path: "/api/auth/dev-sign-in",
  schema: DevSignInSchema,
});
