import { UserProvider } from "@prisma/client";
import { addDays } from "date-fns";
import HttpStatus from "http-status-codes";
import { nanoid } from "nanoid";
import { type NextApiHandler } from "next";
import { object, string } from "yup";
import { api } from "~/lib/api";
import { trackLoginLinkEmailed } from "~/lib/external/segment/server/events";
import { sendLoginLinkEmail } from "~/lib/external/sendinblue/templates";
import { createMutation } from "~/lib/react-query";
import { assertNotNil } from "~/lib/utils";
import { fetchRequiredAuthenticatedUser } from "~/services/auth/fetch-authenticated-user";
import {
  enforceSamlAuthentication,
  SamlAuthenticationEnforcedError,
  selectCompanyForSamlAuthentication,
} from "~/services/auth/saml/enforce-saml-authentication";

export const RequestAuthTokenClientSchema = object({
  email: string().email().required(),
});

type RequestAuthTokenResponse = {
  samlAuthenticationUrl?: string;
};

const handler: NextApiHandler<RequestAuthTokenResponse> = async (req, res) => {
  const input = RequestAuthTokenClientSchema.validateSync(req.body, {
    abortEarly: false,
  });

  const user = await req.prisma.user.findUnique({
    where: { email: input.email.toLowerCase(), invitationNeedsReview: false },
    select: { id: true },
  });

  // To avoid leaking user information, if the email is not found in our database we just fail silently and not send any token
  if (!!user) {
    const token = nanoid();
    const authenticatedUser = await fetchRequiredAuthenticatedUser(req, { userId: user.id });
    const companyId = assertNotNil(authenticatedUser?.companyId);

    const company = await req.prisma.company.findUniqueOrThrow({
      where: { id: companyId },
      select: selectCompanyForSamlAuthentication,
    });

    try {
      enforceSamlAuthentication({
        company,
        profileData: { provider: UserProvider.EMAIL_AND_TOKEN, email: input.email },
      });
    } catch (error) {
      if (error instanceof SamlAuthenticationEnforcedError) {
        return res.status(HttpStatus.OK).json({
          samlAuthenticationUrl: error.samlAuthenticationUrl,
        });
      }

      throw error;
    }

    // There can only be one valid token per email at any point in time
    await req.prisma.userAuthenticationToken.updateMany({ where: { email: input.email }, data: { valid: false } });

    await req.prisma.userAuthenticationToken.create({
      data: {
        email: input.email.toLowerCase(),
        token,
        valid: true,
        expiresAt: addDays(new Date(), 1),
      },
    });

    await sendLoginLinkEmail(req, {
      ...input,
      token,
      locale: authenticatedUser.locale,
    });

    await trackLoginLinkEmailed(req, authenticatedUser);
  }

  res.status(HttpStatus.OK).json({});
};

export default api(handler, {
  method: "POST",
  authentication: { optional: true },
});

export const useRequestTokenMutation = createMutation<typeof handler, typeof RequestAuthTokenClientSchema>({
  path: "/api/auth/token/request",
  schema: RequestAuthTokenClientSchema,
});
